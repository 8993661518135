import * as React from 'react'

import Main from '../components/Main'

const NotFoundPage = () => {
  return (
    <Main>
      <div className="px-1 py-10 md:container md:mx-auto">
        <h1 className="font-unica-one text-3xl text-center text-black tracking-widest leading-loose">
          404
        </h1>
        <h2 className="font-voltaire text-xl text-center text-black tracking-wider leading-relaxed">
          Sivua ei valitettavasti löytynyt!
        </h2>
      </div>
    </Main>
  )
}

export default NotFoundPage
